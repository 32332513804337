import { createWebHistory, createRouter } from "vue-router";
import store from '../state/store'
import routes from './routes';
import appConfig from "../../app.config";

import axiosClient from "../axios";


const router = createRouter({
  history: createWebHistory(),
  routes,
  // Use the HTML5 history API (i.e. normal-looking routes)
  // instead of routes with hashes (e.g. example.com/#/about).
  // This may require some server configuration in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  mode: 'history',
  // Simulate native-like scroll behavior when navigating to a new
  // route and using back/forward buttons.
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0, left: 0 };
    }
  },
});

let shouldContinue = true;

router.beforeEach(async (to, from, next) => {

  /* const currentDate = new Date();
  const targetDate = new Date(2024, 11, 16); // در اینجا ماه دِسامبر با شماره 11 نشان داده می‌شود

  if (currentDate < targetDate) {

  } else {
    next(false); // جلوگیری از اجرای مسیر
  } */

  const { authRequired, isAdmin } = to.meta;

  const { token, data } = store.state.user;
  const userRole = data ? data.ur : null;

  if (authRequired && !token && !store.getters.loggedIn) {
    cleanupAndRedirect(next);
    shouldContinue = false;
  }

  if (shouldContinue && to.meta.guestRequired && token) {
    const result = await redirectToUserRoleDashboard(token, userRole, next);
    if (result.error) {
      console.error(result.error);
      cleanupAndRedirect(next);
      shouldContinue = false;
    } else if (result.redirect) {
      next(result.redirect);
      shouldContinue = false;
    }
  }

  if (shouldContinue && token && userRole) {
    const result = await redirectToUserRoleDashboard(token, userRole, next);
    if (result.error) {
      console.error(result.error);
      cleanupAndRedirect(next);
      shouldContinue = false;
    } else if (result.redirect) {

      if (isAdmin && userRole === 'admin') {
        next();
        shouldContinue = false;
      } else {
        next(result.redirect);
        shouldContinue = false;
      }

    }
  }

  if (shouldContinue && (isAdmin && !store.getters.isAdmin)) {
    next('/');
    shouldContinue = false;
  }

  next(); // اجازه دادن به ادامه‌ی مسیر

});

async function redirectToUserRoleDashboard(token, userRole, next) {
  if (!token) {
    cleanupAndRedirect(next);
    return { error: 'User token is missing.' };
  }

  const response = await axiosClient.get(`/validate-token/${userRole}`);
  if (response.data) {
    const roles = {
      admin: 'dashboard',
    };

    if (store.getters.isAdmin) {
      const redirectPath = { name: roles[userRole] };
      return { redirect: redirectPath };
    } else {
      cleanupAndRedirect(next);
      return { error: 'Invalid user role.' };
    }
  } else {
    cleanupAndRedirect(next);
    return { error: 'Token validation failed.' };
  }
}

function cleanupAndRedirect(next) {
  localStorage.removeItem('USER');
  localStorage.removeItem('USERROLE');
  localStorage.removeItem('TOKEN');
  localStorage.removeItem('LOGGEDIN');
  next('/login');
}


router.beforeResolve(async (routeTo, routeFrom, next) => {
  // Create a `beforeResolve` hook, which fires whenever
  // `beforeRouteEnter` and `beforeRouteUpdate` would. This
  // allows us to ensure data is fetched even when params change,
  // but the resolved route does not. We put it in `meta` to
  // indicate that it's a hook we created, rather than part of
  // Vue Router (yet?).
  try {
    // For each matched route...
    for (const route of routeTo.matched) {
      await new Promise((resolve, reject) => {
        // If a `beforeResolve` hook is defined, call it with
        // the same arguments as the `beforeEnter` hook.
        if (route.meta && route.meta.beforeResolve) {
          route.meta.beforeResolve(routeTo, routeFrom, (...args) => {
            // If the user chose to redirect...
            if (args.length) {
              // If redirecting to the same route we're coming from...
              // Complete the redirect.
              next(...args);
              reject(new Error('Redirected'));
            } else {
              resolve();
            }
          });
        } else {
          // Otherwise, continue resolving the route.
          resolve();
        }
      });
    }
    // If a `beforeResolve` hook chose to redirect, just return.
  } catch (error) {
    return;
  }
  document.title = routeTo.meta.title + ' | ' + appConfig.title;
  // If we reach this point, continue resolving the route.
  next();
});

export default router;
